'use client'

import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import type {
	ClientInitialLookupVariants,
	ClientProductDetail,
	ClientProductVariantDetail,
	ProductRecommendationsData,
} from '~/lib/types/product.interface'
import logger, { createClientLogger } from '~/lib/logger'
import {
	ExclusiveType,
	GetProductPromotionBySkuIdDocument,
	type ColorVariation,
	type GetProductPromotionBySkuIdQuery,
	type VariantProduct,
} from '~/graphql/generated/uacapi/type-document-node'
import { useFeatureFlags } from '../CommerceConfigurationProvider/CommerceConfigurationProvider'
import { isEGiftCard as isEGiftCardType, isGiftCard as isGiftCardType, isVariantOutOfStock } from '~/lib/products'
import { ensureNonNullishArray, ensureString, forceNumber } from '~/types/strict-null-helpers'
import { useQueryWithAuth } from '~/components/hooks/useQueryWithAuth'
import usePersonalization from '~/components/hooks/personalization/usePersonalization'
import { ProductSource, useProducts } from '~/components/providers/ProductProvider/ProductProvider'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import { useLocale } from '~/components/hooks/useLocale'
import { useAbTest } from '../AbTestProvider/AbTestProvider'
import type { UserGeneratedContent } from '~/lib/types/ratings.interface'
import { ReviewsProvider } from './ReviewsProvider/ReviewsProvider'
import { QuestionsProvider } from './QuestionsProvider/QuestionsProvider'
import { submitFeedbackAction } from '~/app/actions/ugc'

export interface ProductDetailContextInterface {
	lookupVariants?: ClientInitialLookupVariants
	isGiftCard: boolean
	isEGiftCard: boolean
	dataLayerUpdate: boolean
	setDataLayerUpdate: React.Dispatch<React.SetStateAction<boolean>>
	isPriceHidden: boolean
	setIsPriceHidden: React.Dispatch<React.SetStateAction<boolean>>
	isVariantLoading: boolean
	setIsVariantLoading: React.Dispatch<React.SetStateAction<boolean>>
	selectedVariant?: ClientProductVariantDetail
	setSelectedVariant: React.Dispatch<React.SetStateAction<ClientProductVariantDetail | undefined>>
	previewVariant?: ClientProductVariantDetail
	setPreviewVariant: React.Dispatch<React.SetStateAction<ClientProductVariantDetail | undefined>>
	displayedColor?: ColorVariation
	setDisplayedColor: React.Dispatch<React.SetStateAction<ColorVariation | undefined>>
	productRecommendationsData?: ProductRecommendationsData
	setProductRecommendationsData: React.Dispatch<React.SetStateAction<ProductRecommendationsData | undefined>>
	displayedPrices?: ClientProductDetail['price']
	setDisplayedPrices: React.Dispatch<React.SetStateAction<ClientProductDetail['price'] | undefined>>
	productPrice: number
	productPromotions?: VariantProduct['productPromotions']
	getVariantPrices: (variant: ClientProductVariantDetail) => ClientProductDetail['price'] | undefined
	forceOutOfStockError: boolean
	setForceOutOfStockError: React.Dispatch<React.SetStateAction<boolean>>
	isNotEligibleToBuyExclusive: boolean
	setIsNotEligibleToBuyExclusive: React.Dispatch<React.SetStateAction<boolean>>
	allowNotifyMe: boolean
	comingSoon: boolean
	soldOut: boolean
	outOfStock: boolean
	availableShopTheLookSizes: string[]
	showSizeLikeMineDropdown: boolean
	hasEnhancedImages?: boolean
	hasEnhancedProductName?: boolean
	hasEnhancedBuyPanel: boolean
	hasEnhancedContent?: boolean
	dataAttributes: Record<string, string>
	routeParams: URLSearchParams
	product: ClientProductDetail | null
}

export const defaultProductDetailContext: ProductDetailContextInterface = {
	lookupVariants: undefined,
	isGiftCard: false,
	isEGiftCard: false,
	dataLayerUpdate: false,
	setDataLayerUpdate: async () => {
		throw new Error('Not implemented. Please include ProductDetailProvider.')
	},
	isPriceHidden: false,
	setIsPriceHidden: async () => {
		throw new Error('Not implemented. Please include ProductDetailProvider.')
	},
	isVariantLoading: false,
	setIsVariantLoading: async () => {
		throw new Error('Not implemented. Please include ProductDetailProvider.')
	},
	selectedVariant: undefined,
	setSelectedVariant: async () => {
		throw new Error('Not implemented. Please include ProductDetailProvider.')
	},
	previewVariant: undefined,
	setPreviewVariant: async () => {
		throw new Error('Not implemented. Please include ProductDetailProvider.')
	},
	displayedColor: undefined,
	setDisplayedColor: async () => {
		throw new Error('Not implemented. Please include ProductDetailProvider.')
	},
	productRecommendationsData: {
		recommendations: [],
		isLoading: false,
		source: ProductSource.UACAPI,
		context: undefined,
	},
	setProductRecommendationsData: async () => {
		throw new Error('Not implemented. Please include ProductDetailProvider.')
	},
	displayedPrices: undefined,
	setDisplayedPrices: async () => {
		throw new Error('Not implemented. Please include ProductDetailProvider.')
	},
	productPrice: 0,
	productPromotions: undefined,
	getVariantPrices: () => {
		throw new Error('Not implemented. Please include ProductDetailProvider.')
	},
	forceOutOfStockError: false,
	setForceOutOfStockError: async () => {
		throw new Error('Not implemented. Please include ProductDetailProvider.')
	},
	isNotEligibleToBuyExclusive: false,
	setIsNotEligibleToBuyExclusive: async () => {
		throw new Error('Not implemented. Please include ProductDetailProvider.')
	},
	allowNotifyMe: false,
	comingSoon: false,
	soldOut: false,
	outOfStock: true,
	availableShopTheLookSizes: [],
	showSizeLikeMineDropdown: false,
	hasEnhancedImages: false,
	hasEnhancedProductName: false,
	hasEnhancedBuyPanel: false,
	hasEnhancedContent: false,
	dataAttributes: {},
	routeParams: new URLSearchParams(),
	product: null,
}

export const ProductDetailContext = createContext(defaultProductDetailContext)

export function useProductDetailContext() {
	return useContext(ProductDetailContext)
}

const personalizationLogger = createClientLogger('mcp')

export default function ProductDetailProvider({
	children,
	product,
	lookupVariants,
	routeParams,
	userGeneratedContent,
}: React.PropsWithChildren<{
	product: ClientProductDetail
	lookupVariants?: ClientInitialLookupVariants
	routeParams: URLSearchParams
	userGeneratedContent?: UserGeneratedContent
}>): React.ReactElement {
	const { isNotifyMeEnabled, hasPdpEnhancedImages, hasPdpEnhancedProductName, hasPdpEnhancedContent, hasPdpJumplink } =
		useFeatureFlags() || {}

	const { giftCardType } = product
	const isGiftCard = useMemo(() => isGiftCardType(giftCardType), [giftCardType])
	const isEGiftCard = useMemo(() => isEGiftCardType(giftCardType), [giftCardType])

	// Flag to determine the data layer call
	const [dataLayerUpdate, setDataLayerUpdate] = useState(false)

	const [isPriceHidden, setIsPriceHidden] = useState(false)
	const [isVariantLoading, setIsVariantLoading] = useState(false)

	/* -------------------- Variant Info -------------------- */
	const [selectedVariant, setSelectedVariant] = useState<ClientProductVariantDetail | undefined>(
		lookupVariants?.initialVariant,
	)
	// TO DO: Confirm that we even need this functionality? I don't believe we show users the price or any other
	// product details when they "preview" or hover over another variant's color swatch. This appears to be
	// tightly coupled with the "dataLayerUpdate" variables on the ProductDetailContent component (JPC 06/2024)
	const [previewVariant, setPreviewVariant] = useState<ClientProductVariantDetail | undefined>(
		lookupVariants?.initialVariant,
	)
	const [displayedColor, setDisplayedColor] = useState(lookupVariants?.initialVariant.colorVariation)

	/* -------------------- Product Recommendations -------------------- */
	const { user } = useSession()
	const locale = useLocale()
	const personalization = usePersonalization()
	const productManager = useProducts({
		locale,
		user,
		allRefinements: [],
		sortOptions: [],
	})
	const interval = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)

	const [productRecommendationsData, setProductRecommendationsData] = useState<ProductRecommendationsData | undefined>({
		recommendations: [],
		isLoading: false,
		source: ProductSource.UACAPI,
		context: undefined,
	})

	useEffect(() => {
		// Define the step size and total target seconds
		const stepSize = 500 // in milliseconds
		const totalTargetSeconds = 3
		// Calculate the number of attempts based on the step size and total target seconds.
		// The interval runs every half second (as defined by stepSize),
		// so remainingTries is calculated to run for the total target seconds.
		let remainingTries = (totalTargetSeconds * 1000) / stepSize

		const setUacapiProductRecs = (uacapiProductRecs) => {
			clearInterval(interval.current)
			uacapiProductRecs.then((productRecs) => {
				setProductRecommendationsData(productRecs)
			})
		}

		// If product recs are available, skipping to refetch or set the timer.
		if (!productRecommendationsData?.recommendations?.length) {
			// Start the UACAPI call
			const uacapiProductRecs = productManager.getUacapiProductRecommendations(ensureString(product.style))
			interval.current = setInterval(async () => {
				remainingTries -= 1
				if (remainingTries > 0) {
					// Get the products recs from Personalization only when campaigns are available.
					try {
						if (personalization?.campaigns?.length) {
							const personalizationProductRecs = await productManager.getPersonalizationProductRecommendations(
								personalization,
							)
							// NOTE! Sometimes we have personalization recs but they are empty. So, we need to fallback to UACAPI recs.
							if (personalizationProductRecs) {
								clearInterval(interval.current)
								setProductRecommendationsData(personalizationProductRecs)
							} else {
								setUacapiProductRecs(uacapiProductRecs)
							}
						}
					} catch (error) {
						personalizationLogger.error(error, 'Error fetching personalized product recs')
						setUacapiProductRecs(uacapiProductRecs)
					}
				} else {
					// If products recs from personalization are not available within 3 seconds, then fallback to the original UACAPI product recs.
					setUacapiProductRecs(uacapiProductRecs)
				}
			}, stepSize) // Run the interval every 'stepSize' milliseconds. In this case, it runs every 500 milliseconds (half a second) for a total of 3 seconds.
		}

		return () => {
			clearInterval(interval.current) // Stop the interval when unmounted and due to a change in deps.
		}
	}, [product.style, personalization, productManager, productRecommendationsData?.recommendations?.length])

	/* -------------------- Pricing -------------------- */
	if (!product?.price) {
		logger.warn(`Did not receive any prices for product with ID ${product.id}`)
	}

	// Initial prices object that can be displayed
	const [displayedPrices, setDisplayedPrices] = useState<ClientProductDetail['price'] | undefined>(
		product?.price ? { ...product?.price } : undefined,
	)

	// Single numerical value for current product price
	const productPrice = useMemo(
		() =>
			forceNumber(
				displayedPrices?.list?.max === displayedPrices?.sale?.max
					? displayedPrices?.list?.max
					: displayedPrices?.sale?.max,
			),
		[displayedPrices],
	)

	/**
	 * Will request this product's promotional data if there is a change to the logged-in user state,
	 * so we can update the price display accordingly.
	 */
	const { data: productPromotionData } = useQueryWithAuth<GetProductPromotionBySkuIdQuery>(
		GetProductPromotionBySkuIdDocument,
		{
			variables: {
				id: selectedVariant?.sku,
			},
		},
	)

	const productPromotions = useMemo(
		() => (productPromotionData?.product as VariantProduct)?.productPromotions,
		[productPromotionData],
	)

	// Compute prices from a given variant
	const getVariantPrices = useCallback(
		(variant: ClientProductVariantDetail): ClientProductDetail['price'] | undefined => {
			if (variant?.prices?.list || variant?.prices?.sale) {
				const { list, sale } = variant.prices
				return {
					list: {
						min: list,
						max: list,
					},
					sale: {
						min: sale,
						max: sale,
					},
				}
			}
			return undefined
		},
		[],
	)

	useEffect(() => {
		setDisplayedPrices(getVariantPrices(productPromotionData?.product as ClientProductVariantDetail) || product.price)
	}, [product.price, productPromotionData, getVariantPrices])

	useEffect(() => {
		if (previewVariant) {
			setDisplayedPrices(getVariantPrices(previewVariant))
		}
	}, [previewVariant, getVariantPrices])

	/* -------------------- OOS -------------------- */
	const [forceOutOfStockError, setForceOutOfStockError] = useState(false)

	useEffect(() => {
		// reset any OOS message from previous variant
		setForceOutOfStockError(false)
	}, [selectedVariant])

	/* -------------------- Loyalty -------------------- */
	const [isNotEligibleToBuyExclusive, setIsNotEligibleToBuyExclusive] = useState(false)

	/* -------------------- Product Form -------------------- */
	// Set up variant orderable state/merch override state
	const [allowNotifyMe, setAllowNotifyMe] = useState(false)
	const [comingSoon, setComingSoon] = useState(
		() => lookupVariants?.initialVariant.inventory?.exclusiveType === ExclusiveType.COMING_SOON,
	)
	const [soldOut, setSoldOut] = useState(
		() => lookupVariants?.initialVariant.inventory?.exclusiveType === ExclusiveType.OUT_OF_STOCK,
	)
	const outOfStock = useMemo(() => {
		return Boolean(selectedVariant && isVariantOutOfStock(selectedVariant))
	}, [selectedVariant])

	useEffect(() => {
		setAllowNotifyMe(
			!!(
				isNotifyMeEnabled &&
				selectedVariant?.sku &&
				!selectedVariant?.inventory?.orderable &&
				selectedVariant?.inventory?.exclusiveType !== ExclusiveType.OUT_OF_STOCK &&
				!isNotEligibleToBuyExclusive
			),
		)
		setComingSoon(
			!!(
				selectedVariant?.sku &&
				selectedVariant?.inventory?.exclusiveType === ExclusiveType.COMING_SOON &&
				!selectedVariant?.inventory?.orderable
			),
		)
		setSoldOut(
			!!(
				selectedVariant?.sku &&
				!selectedVariant?.inventory?.preorderable &&
				!selectedVariant?.inventory?.orderable &&
				selectedVariant?.inventory?.exclusiveType === ExclusiveType.OUT_OF_STOCK
			),
		)
	}, [selectedVariant, isNotifyMeEnabled, isNotEligibleToBuyExclusive])

	const availableShopTheLookSizes = useMemo(() => {
		const shopTheLookColor = product.shopTheLookColors?.find((item) => item.color === displayedColor?.color)
		return ensureNonNullishArray(shopTheLookColor?.images?.map((img) => img?.modelSize))
	}, [product.shopTheLookColors, displayedColor?.color])

	const showSizeLikeMineDropdown = useMemo(
		() => product.categoryTree.some((cat) => cat.showFitModelSelection && availableShopTheLookSizes.length),
		[product?.categoryTree, availableShopTheLookSizes],
	)

	const hasEnhancedImages = hasPdpEnhancedImages
	const hasEnhancedProductName = hasPdpEnhancedProductName
	const hasEnhancedBuyPanel = !!useAbTest('hasPdpEnhancedBuyPanel')
	const hasEnhancedContent = hasPdpEnhancedContent
	const hasJumplink = hasPdpJumplink
	const dataAttributes = useMemo(() => {
		const data: ProductDetailContextInterface['dataAttributes'] = {}
		if (hasEnhancedImages) data['data-pdp-enhanced-images'] = '1'
		if (hasEnhancedBuyPanel) data['data-pdp-enhanced-buy-panel'] = '1'
		if (hasEnhancedContent) data['data-pdp-enhanced-content'] = '1'
		if (hasJumplink) data['data-pdp-jumplink'] = '1'
		return data
	}, [hasEnhancedBuyPanel, hasEnhancedContent, hasEnhancedImages, hasJumplink])

	const contextValue = useMemo(
		() => ({
			lookupVariants,
			isGiftCard,
			isEGiftCard,
			dataLayerUpdate,
			setDataLayerUpdate,
			isPriceHidden,
			setIsPriceHidden,
			isVariantLoading,
			setIsVariantLoading,
			selectedVariant,
			setSelectedVariant,
			previewVariant,
			setPreviewVariant,
			displayedColor,
			setDisplayedColor,
			productRecommendationsData,
			setProductRecommendationsData,
			displayedPrices,
			setDisplayedPrices,
			productPrice,
			productPromotions,
			getVariantPrices,
			forceOutOfStockError,
			setForceOutOfStockError,
			isNotEligibleToBuyExclusive,
			setIsNotEligibleToBuyExclusive,
			allowNotifyMe,
			comingSoon,
			soldOut,
			outOfStock,
			availableShopTheLookSizes,
			showSizeLikeMineDropdown,
			hasEnhancedImages,
			hasEnhancedProductName,
			hasEnhancedBuyPanel,
			hasEnhancedContent,
			hasJumplink,
			dataAttributes,
			routeParams,
			product,
		}),
		[
			lookupVariants,
			isGiftCard,
			isEGiftCard,
			dataLayerUpdate,
			setDataLayerUpdate,
			isPriceHidden,
			setIsPriceHidden,
			isVariantLoading,
			setIsVariantLoading,
			selectedVariant,
			setSelectedVariant,
			previewVariant,
			setPreviewVariant,
			displayedColor,
			setDisplayedColor,
			productRecommendationsData,
			setProductRecommendationsData,
			displayedPrices,
			setDisplayedPrices,
			productPrice,
			productPromotions,
			getVariantPrices,
			forceOutOfStockError,
			setForceOutOfStockError,
			isNotEligibleToBuyExclusive,
			setIsNotEligibleToBuyExclusive,
			allowNotifyMe,
			comingSoon,
			soldOut,
			outOfStock,
			availableShopTheLookSizes,
			showSizeLikeMineDropdown,
			hasEnhancedImages,
			hasEnhancedProductName,
			hasEnhancedBuyPanel,
			hasEnhancedContent,
			hasJumplink,
			dataAttributes,
			routeParams,
			product,
		],
	)

	return (
		<ProductDetailContext.Provider value={contextValue}>
			<ReviewsProvider
				submitFeedbackAction={submitFeedbackAction}
				product={product}
				userGeneratedContent={userGeneratedContent}
			>
				<QuestionsProvider
					submitFeedbackAction={submitFeedbackAction}
					product={product}
					userGeneratedContent={userGeneratedContent}
				>
					{children}
				</QuestionsProvider>
			</ReviewsProvider>
		</ProductDetailContext.Provider>
	)
}
