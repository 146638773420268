'use client'

import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	type Dispatch,
	type PropsWithChildren,
} from 'react'
import type { AggregateRatings, ReviewsForDisplay, UserGeneratedContent } from '~/lib/types/ratings.interface'
import { ensureArray, ensureNumber, ensureString, isNonNullish } from '~/types/strict-null-helpers'
import { useLocale } from '~/components/hooks/useLocale'
import type { ReviewStatisticsResponse, SummaryResponse } from '~/lib/edge-only/ratings/bazaar-voice'
import {
	getContentLocales,
	getFeedbackStatusMapFromStorage,
	updateFeedbackStatusMap,
	updateReviewDataWithFeedback,
	type Feedback,
	type FeedbackStatus,
	type SubmitFeedbackAction,
} from '~/lib/ugc/ugc'
import type { ClientProductDetail } from '~/lib/types/product.interface'

export const REVIEWS_PAGINATION_LIMIT = 10

export interface ReviewsProviderProps {
	userGeneratedContent?: Omit<UserGeneratedContent, 'questions'>
	fetchImpl?: typeof fetch
	product: ClientProductDetail | null
	submitFeedbackAction: SubmitFeedbackAction
}

interface ReviewFilters {
	/** The offset to use */
	offset?: number
	/** The sort to use */
	sort?: string
	/** The search term to use */
	search?: string
	/** Set to clear all filters */
	clear?: boolean
}

interface ReviewsDispatch {
	dispatch: Dispatch<Action>
	handleLoadMore: () => void
	submitFeedback: (feedback: Feedback) => void
	updateFilter: (filters: ReviewFilters, clear?: boolean) => void
}

export type ReviewContextValue = {
	aggregateRatings?: AggregateRatings
	productDisplay: ReviewStatisticsResponse
	summary: SummaryResponse
	/** Initial reviews */
	reviews: ReviewsForDisplay
	/** Review data updated via pagination */
	reviewData: ReviewsForDisplay
	/** Has more items to paginate */
	hasMore: boolean
	/** Is loading more items via pagination */
	isLoading: boolean
	/** The error message if an error ocurred during pagination */
	error: string
	/** The feedback status stored per browser session */
	feedbackStatusMap: Record<string, FeedbackStatus>
	/** The search params used to query reviews */
	searchParams?: URLSearchParams
}

function hasMorePages(offset: number, limit: number, total: number) {
	return offset + limit < total - 1
}

function getHasMore(resultSet: ReviewsForDisplay) {
	return hasMorePages(
		ensureNumber(resultSet?.offset),
		ensureNumber(resultSet?.limit),
		ensureNumber(resultSet?.totalResults),
	)
}

function getDefaultSearchParams(product: ClientProductDetail | null, defaulLocaleFilter: string) {
	const params = new URLSearchParams()
	params.append('filter', `ProductId:${product?.style}`)
	params.append('filter', `ContentLocale:${defaulLocaleFilter}`)
	params.append('sort', 'rating:desc')
	return params
}

const initialReviewContextValue = {
	productDisplay: {} as ReviewStatisticsResponse,
	summary: {} as SummaryResponse,
	reviews: {},
	reviewData: {},
	hasMore: false,
	isLoading: false,
	error: '',
	feedbackStatusMap: {},
}

const ReviewsContext = createContext<ReviewContextValue>(initialReviewContextValue)
const ReviewsDispatchContext = createContext<ReviewsDispatch>({} as ReviewsDispatch)

export function ReviewsProvider({
	userGeneratedContent: { aggregateRatings, reviews: initialReviews, productDisplay, summary } = {
		aggregateRatings: undefined,
		reviews: {},
		summary: {} as SummaryResponse,
		productDisplay: {} as ReviewStatisticsResponse,
	},
	children,
	product,
	submitFeedbackAction,
	fetchImpl = fetch,
}: PropsWithChildren<ReviewsProviderProps>) {
	const locale = useLocale()
	const defaulLocaleFilter = getContentLocales(locale).join(',')
	const defaultSearchParams = getDefaultSearchParams(product, defaulLocaleFilter)
	const previousSearchParams = useRef(defaultSearchParams.toString())

	const [state, dispatch] = useReducer(reviewsReducer, {
		aggregateRatings,
		reviews: initialReviews,
		reviewData: { ...initialReviews },
		hasMore: getHasMore(initialReviews),
		isLoading: false,
		error: '',
		summary,
		productDisplay,
		feedbackStatusMap: {},
		searchParams: defaultSearchParams,
	})

	useEffect(() => {
		async function loadFeedbackStatusMap() {
			const data = getFeedbackStatusMapFromStorage()
			dispatch({ type: 'update-feedback-status', data })
		}
		loadFeedbackStatusMap()
	}, [])

	// Perform a fetch whenever the searchParams change
	useEffect(
		() => {
			const params = ensureString(state.searchParams?.toString())
			if (params === previousSearchParams.current) return

			previousSearchParams.current = params

			dispatch({ type: 'loading' })
			fetchImpl(`/api/reviews/?resource=reviews&${params}`)
				.then(async (response) => {
					const json = await response.json()

					// If the offset is greater than 0, then this is a pagination result that can be appended to the existing results
					let results = [...json.results]
					if (ensureNumber(json.offset) > 0) {
						results = [...ensureArray(state.reviewData.results), ...ensureArray(json.results)]
					}

					dispatch({ type: 'fetched', data: { ...json, results } })
				})
				.catch((errorMessage) => {
					dispatch({ type: 'error', data: errorMessage })
				})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state.searchParams?.toString()],
	)

	const updateFilter = useCallback(
		({ offset, sort, search, clear }: ReviewFilters) => {
			let searchParams = new URLSearchParams(state.searchParams)

			switch (true) {
				case isNonNullish(offset):
					searchParams.set('offset', String(offset))
					break
				case isNonNullish(sort):
					searchParams.set('sort', sort)
					searchParams.set('offset', '0')
					break
				case isNonNullish(search):
					searchParams.set('search', search)
					searchParams.set('offset', '0')
					break
				case isNonNullish(clear):
					searchParams = new URLSearchParams(defaultSearchParams)
					break
			}

			dispatch({ type: 'update-search', data: searchParams })
		},
		[defaultSearchParams, state.searchParams],
	)

	const handleLoadMore = useCallback(() => {
		updateFilter({ offset: ensureNumber(state.reviewData.offset) + REVIEWS_PAGINATION_LIMIT })
	}, [state.reviewData.offset, updateFilter])

	const submitFeedback = useCallback(
		async (feedback: Feedback) => {
			dispatch({
				type: 'update-feedback-status',
				data: updateFeedbackStatusMap(state.reviewData, state.feedbackStatusMap, feedback),
			})
			dispatch({ type: 'fetched', data: updateReviewDataWithFeedback(state.reviewData, feedback) })
			await submitFeedbackAction(feedback)
		},
		[state.reviewData, state.feedbackStatusMap, submitFeedbackAction],
	)

	const actions = useMemo(
		() => ({
			dispatch,
			handleLoadMore,
			submitFeedback,
			updateFilter,
		}),
		[handleLoadMore, submitFeedback, updateFilter],
	)

	return (
		<ReviewsContext.Provider value={state}>
			<ReviewsDispatchContext.Provider value={actions}>{children}</ReviewsDispatchContext.Provider>
		</ReviewsContext.Provider>
	)
}

export function useReviews() {
	return useContext(ReviewsContext)
}

export function useReviewsDispatch() {
	return useContext(ReviewsDispatchContext)
}

type Action =
	| { type: 'error'; data: string }
	| { type: 'fetched'; data: ReviewContextValue['reviewData'] }
	| { type: 'loading' }
	| { type: 'update-feedback-status'; data: Record<string, FeedbackStatus> }
	| { type: 'update-search'; data: URLSearchParams }

function reviewsReducer(state: ReviewContextValue, action: Action) {
	switch (action.type) {
		case 'loading':
			return { ...state, isLoading: true }
		case 'error':
			return { ...state, isLoading: false, error: action.data }
		case 'fetched':
			return { ...state, isLoading: false, reviewData: action.data, error: '', hasMore: getHasMore(action.data) }
		case 'update-feedback-status':
			return { ...state, feedbackStatusMap: action.data }
		case 'update-search':
			return { ...state, searchParams: action.data }
		default:
			return { ...state }
	}
}
