import { UaConfigPublicImpl } from '../client-only/ua-config'
import { getLocaleVariantFormat } from '../i18n/locale'
import { ensureNumber, ensureString, isNonNullish } from '~/types/strict-null-helpers'
import type { ReviewsForDisplay } from '../types/ratings.interface'
import type { Question } from '~/openapi/generated/bazaar-voice-display/models/Question'
import type { SubmitFeedback } from '~/openapi/generated/bazaar-voice-submission/models/SubmitFeedback'

const FEEDBACK_STORAGE_KEY = 'bvf'

export interface Feedback {
	contentId: string
	feedbackType: 'helpfulness' | 'inappropriate'
	contentType: 'review' | 'question' | 'answer' | 'review_comment'
	vote?: 'Positive' | 'Negative'
}

export interface FeedbackStatus {
	positive?: number
	negative?: number
	inappropriate?: number
}

export type SubmitFeedbackAction = (feedback: Feedback) => Promise<
	| {
			success: boolean
			bvResponse: SubmitFeedback
	  }
	| undefined
>
export type FeedbackStatusMap = Record<string, FeedbackStatus>

export function updateReviewDataWithFeedback(reviewData: Question, feedback: Feedback): Question
export function updateReviewDataWithFeedback(reviewData: ReviewsForDisplay, feedback: Feedback): ReviewsForDisplay
export function updateReviewDataWithFeedback(reviewData: Question | ReviewsForDisplay, feedback: Feedback) {
	const review = reviewData.results?.find((result) => result.id === feedback.contentId)
	if (review) {
		switch (feedback.feedbackType) {
			case 'inappropriate':
				if (isNonNullish(review.totalInappropriateFeedbackCount)) review.totalInappropriateFeedbackCount += 1
				break
			case 'helpfulness':
				switch (feedback.vote) {
					case 'Negative':
						if (isNonNullish(review.totalNegativeFeedbackCount)) review.totalNegativeFeedbackCount += 1
						break
					case 'Positive':
						if (isNonNullish(review.totalPositiveFeedbackCount)) review.totalPositiveFeedbackCount += 1
						break
				}
				break
		}
	}
	return reviewData
}

export function updateFeedbackStatusMap(
	reviewData: ReviewsForDisplay,
	statusMap: FeedbackStatusMap,
	{ contentId, feedbackType, vote }: Feedback,
): FeedbackStatusMap
export function updateFeedbackStatusMap(
	reviewData: Question,
	statusMap: FeedbackStatusMap,
	{ contentId, feedbackType, vote }: Feedback,
): FeedbackStatusMap
export function updateFeedbackStatusMap(
	reviewData: ReviewsForDisplay | Question,
	statusMap: FeedbackStatusMap,
	{ contentId, feedbackType, vote }: Feedback,
) {
	// eslint-disable-next-line no-param-reassign -- needed for performance
	if (!statusMap[contentId]) statusMap[contentId] = {}
	const existingReview = reviewData.results?.find((x) => x.id === contentId)
	let key = ''
	let newCount = 0
	switch (feedbackType) {
		case 'helpfulness':
			switch (vote) {
				case 'Positive':
					key = 'positive'
					newCount = ensureNumber(existingReview?.totalPositiveFeedbackCount) + 1
					break
				case 'Negative':
					key = 'negative'
					newCount = ensureNumber(existingReview?.totalNegativeFeedbackCount) + 1
					break
			}
			break
		case 'inappropriate':
			key = 'inappropriate'
			newCount = ensureNumber(existingReview?.totalInappropriateFeedbackCount) + 1
			break
	}
	// eslint-disable-next-line no-param-reassign -- needed for performance
	statusMap[contentId][key] = newCount
	saveFeedbackStatusMapToStorage(statusMap)
	return statusMap
}

/**
 * Gets the count from the current user session, taking into
 * account any optimistic updates that have occurred recently.
 * The optimistic count is needed because there's a delay from the
 * time a count is recorded in BV vs the count appearing in the
 * display API response.
 */
export function getCountFromSession(feedbackStatus: FeedbackStatus, type: keyof FeedbackStatus, countFromApi: number) {
	if (!feedbackStatus) return countFromApi
	return Math.max(ensureNumber(feedbackStatus[type]), countFromApi)
}

function safeCall(callback: () => void) {
	try {
		callback()
	} catch {
		/** Ignore */
	}
}

export function getFeedbackStatusMapFromStorage() {
	let map: FeedbackStatusMap = {}
	safeCall(() => {
		const json = window.localStorage.getItem(FEEDBACK_STORAGE_KEY)
		map = decodeData(ensureString(json))
	})
	return map
}

export function saveFeedbackStatusMapToStorage(map: FeedbackStatusMap) {
	safeCall(() => {
		window.localStorage.setItem(FEEDBACK_STORAGE_KEY, encodeData(map))
	})
}

function encodeData(data: unknown) {
	let text = ''
	safeCall(() => {
		text = btoa(JSON.stringify(data))
	})
	return text
}

function decodeData(text: string) {
	let data = {}
	safeCall(() => {
		data = JSON.parse(atob(text))
	})
	return data
}

export function getContentLocales(locale: string) {
	return Object.keys(
		UaConfigPublicImpl.bazaarVoiceLocales.reduce(
			(previous, contentLocale) => ({
				...previous,
				[getLocaleVariantFormat(contentLocale)]: true,
			}),
			{
				[getLocaleVariantFormat(locale)]: true,
			},
		),
	)
}
